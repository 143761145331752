import './App.css';
import Layout from './components/Layout';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import BookSection from './components/BookSection';
import TechSection from './components/TechSection';
import Accounts from './components/Accounts';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main, •
      main: '#3897D3',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //     main: ''
    // }

  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout >
        <Switch>
					<Route path="/" exact component={BookSection} />
					<Route path="/techsection" component={TechSection} />
					<Route path="/accounts" component={Accounts} />
				</Switch>
        </Layout>
      </Router>
    </ThemeProvider> 
  );
}

export default App;
