import { configureStore } from '@reduxjs/toolkit';
import newBookPageReducer from '../components/BookSection/BookSlice';
import bookIDReducer from '../components/BookSection/BookIDSlice';

export const store = configureStore({
  reducer: {
    newBookPage: newBookPageReducer,
    bookID: bookIDReducer,
  },
});

