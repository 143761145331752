import React from 'react'
import WellcomePage from './components/WellcomePage';
import { useState } from 'react';
import NewBook from './components/NewBook';
import { useSelector, useDispatch } from 'react-redux';
import { toggleState } from './BookSlice';

const BookSection = () => {
    // const [newBook, setNewBook] = useState(false);
    let newBookPage = useSelector(state=>state.newBookPage);
    // const dispatch = useDispatch();
    // dispatch(toggleState())
    // console.log('state1: ', newBookPage)
    // const onNewSummaryButtonClick = () =>{
    //     setNewBook(!newBook)
    // }

    return (
        <div>
            {!newBookPage && <WellcomePage />}
            {newBookPage && <NewBook />}
        </div>
    )
}

export default BookSection
