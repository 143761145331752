import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { toggleState, trueState } from '../../BookSlice';
import { useState, useEffect } from 'react';
import { MarkAsUnreadRounded } from '@mui/icons-material';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { setBookID } from '../../BookIDSlice';

const StyledBox = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
}));

const useStyles = makeStyles((theme)=>({
    searchField: {
        minWidth: '300px',
        // [theme.breakpoints.down('xs')]: {
        //     maxWidth: '100%',
        //     },
        // height: '30px'
    },
    table: {
        // border: '1px solid red',
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

export default function WellcomePage(theme) {
  const [bookList, setBookList] = useState([])
  const [searchField, setSearchField] = useState('')
  const [reload, setReload] = useState(true)
  const classes = useStyles();

  useEffect(()=>{
      fetch('https://test.mebrekindustrialengineering.com/api/getbooks')
          .then(response => response.json())
          .then(books => {setBookList(books)})
  },[reload])

  // console.log(bookList)

  const dispatch = useDispatch();
  const onReload = () => setReload(!reload)

  const onNewSummaryClick = () => { dispatch(trueState())}
  // const onEditSummaryClick = (id) => { dispatch(setBookID(id)); dispatch(trueState())}

  return (
        <Box sx={{height: '60vh', width: '100%'}} > {/*border: '1px solid gray',*/}
            <StyledBox sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete 
                    id="free-solo-demo"
                    freeSolo
                    // options={top100Films.map((option) => option.title)}
                    options={bookList.map(book => book.name)}
                    renderInput={(params) => (
                    <TextField 
                        className={classes.searchField}
                        {...params} 
                        label="Search books" 
                        margin="normal" 
                        variant="outlined" 
                        size="small"
                        value={searchField}
                        onChange={(e)=>{setSearchField(e.target.value)}}
                        />
                    )}
                />
                <Button 
                    variant="contained" 
                    sx={{height: 40, margin: 2}}
                    onClick={()=>{dispatch(setBookID(0)); dispatch(trueState())}}
                    >New Summary</Button>
            </StyledBox>
            
            <TableContainer component={Paper} className={classes.table}>
            <Table sx={{ minWidth: 650}}aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell align="right">Autor</StyledTableCell>
                    <StyledTableCell align="right">Category</StyledTableCell>
                    <StyledTableCell align="right">Published Date</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {bookList.filter(book => book.name.toLowerCase().includes(searchField.toLowerCase()) ||
                                         book.autor.toLowerCase().includes(searchField.toLowerCase())).map((book) => (
                    <StyledTableRow key={book.name}>
                    <StyledTableCell component="th" scope="row">
                        {book.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{book.autor}</StyledTableCell>
                    <StyledTableCell align="right">{book.category}</StyledTableCell>
                    <StyledTableCell align="right">{book.date.slice(0, 10)}</StyledTableCell>
                    <StyledTableCell align="right">
                    <ButtonGroup disableElevation variant="contained">
                      <Button
                        onClick={()=>{dispatch(setBookID(book.id)); dispatch(trueState())}}
                        >
                        <EditIcon />
                      </Button>
                      <Button 
                        color="error"
                        onClick={()=>{
                          if(window.confirm("Are you sure you want to delete this book?")){
                            fetch(`https://test.mebrekindustrialengineering.com/api/deletebook/${book.id}`)
                            onReload()
                          }
                          }}>
                        <DeleteForeverIcon/>
                      </Button>
                    </ButtonGroup>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>

  );
}
