import React from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const StyledBox = styled(Box)(({theme})=>({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '70%',
        // border: '1px solid red',
        [theme.breakpoints.down('md')]: {
            width: '100%',
          },
}));

const StyledBox2 = styled(Box)(({theme})=>({
    // border: '1px solid green',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '25px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        width: '80%'
      },
}));

const StyledBox3 = styled(Box)(({theme})=>({
    // border: '1px solid green',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center'
      },
}));

const StyledBox4 = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // border: '1px solid red'
}));

const StyledImg = styled('img')(({theme})=>({
    height: '200px',
    border: '1px solid red',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
        marginTop: '20px'
      },
}));

const NewBook = () => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [autor, setAutor] = useState('');
    const [image, setImage] = useState('');
    const [points, setPoints] = useState('');
    const [book, setBook] = useState('');

    const bookId = useSelector(state => state.bookID)
    console.log('book id: ', bookId)
    // http://test.mebrekindustrialengineering.com/api/
    useEffect(()=>{
        if(bookId > 0)
        fetch(`https://test.mebrekindustrialengineering.com/api/getbook/${bookId}`)
                .then(response => response.json())
                .then(book => {
                        setBook(book[0]); 
                        setPoints(book[0].points);
                        setName(book[0].name)
                        setAutor(book[0].autor)
                        setCategory(book[0].category)
                        console.log(book[0])
                    })
    },[])
    // if(bookId > 0){
            // fetch(`http://localhost:3010/getbook/${bookId}`)
            //     .then(response => response.json())
            //     .then(book => {
            //             setBook(book[0]); 
            //             setPoints(book[0].points);
            //             console.log(book[0])
            //         })
    // }

    const onNameChange = (e) => {
        setName(e.target.value)
    }
    const onAutorChange = (e) => {
        setAutor(e.target.value)
    }
    const onFileSelect = (e) => {
        // setImage(URL.createObjectURL(e.target.files[0]))
        console.log('image: ', URL.createObjectURL(e.target.files[0]))
    }
    const onPointsChange = (e) => {
        setPoints(e.target.value)
    }

    return (
        <form action={ bookId > 0 ? `https://test.mebrekindustrialengineering.com/api/updatebook/${bookId}` : "https://test.mebrekindustrialengineering.com/api/addbook"} method="POST" enctype="multipart/form-data">
        <StyledBox3>   
            <StyledBox>
                    <StyledBox4>
                    <TextField 
                        id="outlined-basic" 
                        label="Book Name" 
                        variant="outlined" 
                        size="small"
                        value={name}
                        name="name"
                        onChange={onNameChange}
                        sx={{marginBottom: 2, maxWidth: "400px"}}
                        required 
                        />
                    <TextField 
                        id="outlined-basic" 
                        label="Autor" 
                        variant="outlined" 
                        size="small"
                        value={autor}
                        name="autor"
                        onChange={onAutorChange}
                        sx={{marginBottom: 2, maxWidth: "400px"}}
                        required
                        />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" size="small">Category</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        label="Category"
                        // onChange={handleChange}
                        size="small"
                        name="category"
                        sx={{maxWidth: "400px"}}
                        required
                        >
                        <MenuItem value="psychology">Psychology</MenuItem>
                        <MenuItem value="phylosophy">Philosophy</MenuItem>
                        <MenuItem value="Biography">Biography</MenuItem>
                        <MenuItem value="Education">Education</MenuItem>
                        <MenuItem value="Motivation">Motivation</MenuItem>
                        <MenuItem value="Success">Success</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Science">Science</MenuItem>
                        <MenuItem value="Communication">Communication</MenuItem>
                        <MenuItem value="Meditation">Meditation</MenuItem>
                        </Select>  
                    </FormControl>  
                    <TextField
                            id="outlined-multiline-static"
                            label="Points"
                            multiline
                            rows={12}
                            value={points}
                            name="points"
                            onChange={onPointsChange}
                            // defaultValue="Default Value"
                            sx={{marginTop: 3}}
                            required
                            />
                    </StyledBox4>
            </StyledBox>
            <StyledBox2>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledImg src="" />
                    <Button
                        variant="contained"
                        component="label"
                        sx={{marginTop: 2}}
                        size="small"
                        >
                        Upload Cover image
                        <input
                            type="file"
                            hidden
                            name="bookImage"
                            onChange={onFileSelect}
                            required
                        />
                    </Button>
                </Box>
                <a href="http://programminghead.com">
                <Button 
                    variant="contained" 
                    startIcon={<SaveIcon />}
                    color="primary"
                    sx={{marginTop: 2, marginBottom: 2}}
                    type="submit"
                    // onclick="window.location.href = 'http://localhost:3001/booksection';"
                    // component={Link}
                    // to="/techsection"
                    >
                    Save
                </Button>
                </a>
            </StyledBox2>
        </StyledBox3>
     </form>
    )
}

export default NewBook
