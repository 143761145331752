import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const bookSlice = createSlice({
    name: 'newBookPage',
    initialState,
    reducers: {
        toggleState (state){
            if(state.newBookPage === false){
                return true
            }else{
                return false
            }
        },
        falseState (state) {
            return false
        },
        trueState (state) {
            return true
        },
    }
});

export const { toggleState, falseState, trueState } =  bookSlice.actions;
export default bookSlice.reducer;